import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCloverAuthAPI from './hooks/useCloverAuth';
import { CloverAuthResponse } from '../../types';
import { SnackbarService } from 'app/components/Snackbar';
import Common from 'app/i18n/Common';
import Integrations from 'app/i18n/Integrations';
import Link from 'app/components/Link';
import { APP_CONFIG } from 'app/consts/config';
import { getRedirectTo, isUrlContainsDomain } from '../../utils/redirectTo';

const useCloverAuth = (location: RouteComponentProps['location']) => {
  const { execute } = useCloverAuthAPI();

  useEffect(() => {
    const authenticateClover = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');
      const clientId = queryParams.get('client_id');
      const merchantId = queryParams.get('merchant_id');

      if (code && clientId && merchantId) {
        const result: CloverAuthResponse = await execute({ code, clientId, merchantId });

        if (result.success) {
          SnackbarService.show({ message: 'Successfully authenticated with Clover' });
          const redirectTo = getRedirectTo();
          if (redirectTo && isUrlContainsDomain(redirectTo)) {
            window.setTimeout(function () {
              window.location.href = redirectTo;
            }, 3000);
          }
        } else {
          SnackbarService.showError({
            message: result.message || 'Some error occurred. Please try again later.',
          });
          /* Redirect user to our Clover App */
          window.setTimeout(function () {
            window.location.href = `${APP_CONFIG.clover.appMarketUrl}`;
          }, 3000);
        }
      }
    };

    authenticateClover();
  }, [location.search, execute]);

  return null;
};

function Clover({ location }: RouteComponentProps) {
  useCloverAuth(location);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 lg:pl-[4rem]">
      <div className="container flex flex-col md:flex-row xl:gap-[3rem] items-center justify-between p-8">
        <div className="w-full md:w-1/2 text-left">
          <p
            style={{ lineHeight: '1.1' }}
            className="font-bold mb-20 xl:text-[4.5rem] text-[3rem] md:text-[4rem] xl:leading-tight"
          >
            Procure for
            <br />
            <span className="text-[#27c499]">Clover</span>
            <br />
            Restaurants
          </p>

          <p className="w-full md:w-[70%] mx-auto md:mx-0 my-32 text-[#6b6f85] text-[1.2rem] xl:text-[1.4rem]">
            {Integrations.clover.tagLine}
          </p>

          <Link href={`${APP_CONFIG.clover.appMarketUrl}`} target='blank' >
            <button className="bg-[#27c499] text-white px-32 py-16 xl:px-36 xl:py-20 rounded-lg mt-16 mb-24 md:mb-0">
              {Common.Actions.Connect}
            </button>
          </Link>
        </div>

        <div className="w-full left-0 md:left-[3rem] md:w-1/2 relative h-[300px] md:h-[500px] xl:h-[500px] flex justify-center md:justify-end mt-12 md:mt-0">
          <img
            className="absolute z-10 w-[250px] md:w-[350px] xl:w-[400px] h-auto top-[10%] max-w-[80%] left-0 md:left-[-8%] xl:left-[-10%]"
            src={`${process.env.PUBLIC_URL}/images/clover-logo.png`}
            alt="clover-logo"
          />
          <img
            className="absolute left-[10%] md:left-20 xl:left-[15%] w-[300px] md:w-[420px] xl:w-[460px] max-w-full"
            src={`${process.env.PUBLIC_URL}/images/clover-procure-logo.svg`}
            alt="procure"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Clover);