export type LoginFormProps = {
  disabled: boolean;
  onSubmit: (props: LoginInput) => void;
};

export type LoginInput = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

export type LoginTokens = {
  accessToken: string;
  refreshToken: string;
};

export type LoginTokenResponse = {
  message?: string;
  success: boolean;
  loginToken: LoginTokens;
};

export const enum ForgotPasswordTokenEnum {
  TOKEN_INVALID = 'TOKEN_INVALID',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export type CloverAuthInput = {
  code: string;
  clientId: string;
  merchantId: string;
};

export type CloverAuthResponse = {
  message?: string;
  success: boolean;
  loginToken: LoginTokens;
};
