import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';
import { useAccessControl } from 'app/components/AccessControl';
import adminRouteHierarchy from 'app/consts/adminRouteHierarchy';
import routes from 'app/consts/routes';
import NotificationContextProvider from 'app/context/notificationContext';
import {
  AccessControlRoute,
  Redirect,
  ReloadPersistedRoute,
  Route,
  Router,
  ScrollToTop,
  Switch,
} from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import NewAssetAuditReport from 'app/modules/reports/views/AuditReport/views/NewAssetAuditReport';
import NewInventoryAuditReport from 'app/modules/reports/views/AuditReport/views/NewInventoryAuditReport';
import { isBackgroundWhite } from 'app/utils/isBackgroundWhite';

import {
  getRedirectTo,
  getRedirectToBasedOnPermission,
  isUrlContainsDomain,
} from '../modules/auth/utils/redirectTo';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../types/schema';
import AssetModelsPage from './admin/asset-models';
import EditAssetModelPage from './admin/asset-models/[id]';
import AddAssetModelsPage from './admin/asset-models/add';
import Categories from './admin/categories';
import EditCategory from './admin/categories/[id]';
import AddCategory from './admin/categories/add';
import CompnayPage from './admin/company';
import ContactPage from './admin/contacts';
import Departments from './admin/departments';
import EditDepartment from './admin/departments/[id]';
import AddDepartment from './admin/departments/add';
import ImportsPage from './admin/import';
import Labels from './admin/labels';
import Locations from './admin/locations';
import EditLocation from './admin/locations/[id]';
import AddLocation from './admin/locations/add';
import Manufacturer from './admin/manufacturers';
import EditManufacturer from './admin/manufacturers/[id]';
import AddManufacturer from './admin/manufacturers/add';
import Organizations from './admin/organizations';
import EditOrganization from './admin/organizations/[id]';
import AddOrganization from './admin/organizations/add';
import Partners from './admin/partners';
import EditPartner from './admin/partners/[id]';
import InvitePartnerPage from './admin/partners/invite';
import PrintLabel from './admin/print-label';
import ProfilePage from './admin/profile/profile';
import Projects from './admin/projects';
import EditProject from './admin/projects/[id]';
import AddProject from './admin/projects/add';
import Roles from './admin/roles';
import EditRole from './admin/roles/[id]';
import AddRole from './admin/roles/add';
import Sites from './admin/sites';
import EditSite from './admin/sites/[id]';
import AddSite from './admin/sites/add';
import UndeletePage from './admin/undelete';
import UndeleteAssetPage from './admin/undelete/asset';
import UndeleteInventoryPage from './admin/undelete/inventory';
import UndeleteUserPage from './admin/undelete/user';
import Users from './admin/users';
import EditUser from './admin/users/[id]';
import AddUser from './admin/users/add';
import Warehouse from './admin/warehouse';
import EditAssetPage from './assets/[id]';
import AddAssetPage from './assets/add';
import CheckInAssetsPage from './assets/checkIn';
import CheckOutAssetsPage from './assets/checkout';
import AssetEditTransactionsPage from './assets/confirmation/edit-transactions/[id]';
import DeletedAssetPage from './assets/deleted';
import MoveAssetsPage from './assets/move';
import RemoveAssetsPage from './assets/remove';
import ReserveAssetsPage from './assets/reserve';
import RestockAssetsPage from './assets/restock';
import SearchAssetsPage from './assets/search';
import ForgotPassword from './auth/forgot-password';
import LoginPage from './auth/login';
import LoginRedirect from './auth/LoginRedirect';
import SetPassword from './auth/set-password';
import SignUpPage from './auth/signup';
import EditInventory from './inventory/[id]';
import AddInventoryPage from './inventory/add';
import EditTransactionsPage from './inventory/confirmation/edit-transactions/[id]';
import DeletedInventoryPage from './inventory/deleted';
import MoveInventoryPage from './inventory/move';
import RemoveInventoryPage from './inventory/remove';
import RestockInventoryPage from './inventory/restock';
import ITAssetUsersPage from './network/itAssetUsers';
import NetworkAssetDetail from './network/networkAssetDetail';
import NetworkAssetEdit from './network/networkAssetEdit';
import NetworkAssetsInsights from './network/networkAssetInsights';
import NetworkAssetsPage from './network/networkAssets';
import NetworkDashboardPage from './network/networkDashboard';
import NetworkDiscoveredAssetsPage from './network/networkDiscoveredAssets';
import NetworkDiscoveryTool from './network/networkDiscoveryTool';
import OrderHistoryPage from './orders/history';
import OrderDetailsPage from './orders/history/[id]';
import ExternalOrderDetailPage from './orders/history/external/[id]';
import InternalOrderDetailPage from './orders/history/internal/[id]';
import PrintReturnedOrderPage from './orders/print-returned-order';
import OrderRequestPage from './orders/request';
import OrderRequestAgainPage from './orders/request/[id]';
import ReceivingHistory from './receiving/history';
import ReceivingReceive from './receiving/receive';
import ReceivingSchedule from './receiving/schedule';
import SearchContainerPage from './ship/containerSearch';
import SearchShipmentPage from './ship/shipmentSearch';
import AffiliateConfigurationPage from './shop/admin';
import EditAffiliateConfigurationPage from './shop/admin/[id]';
import AddAffiliateConfigurationPage from './shop/admin/add';
import Suppliers from './shop/suppliers/suppliers';
import WishlistEditListPage from './wishlist/[id]';
import ConfirmationViewPage from './wishlist/confirmationViewPage';
import WishlistCreateListPage from './wishlist/createListPage';
import InviteWishlistPage from './wishlist/invite';
import WishlistLandingPage from './wishlist/landingPage';
import WishlistListViewPage from './wishlist/listView';
import WishlistReferOtherPage from './wishlist/referOtherPage';
import WishlistSettingsPage from './wishlist/settingsPage';
import WishlistSharePage from './wishlist/sharePage';
import Error5XX from './error-5xx';
import FilePreviewPage from './file-preview';
import Inventories from './inventory';
import Reports from './reports';
import WishlistPage from './wishlist';
import CloverLandingPage from './auth/clover';

interface Props {
  isLoggedIn: boolean;
}

function Routes(props: Props) {
  const { isLoggedIn } = props;

  const { id, workspacePermissions } = useCurrentUser();
  const location = useLocation();

  const redirectLocation = useMemo(() => {
    const redirectTo = getRedirectTo();

    if (redirectTo) {
      const isUrlContainDomain = isUrlContainsDomain(redirectTo);
      if (isLoggedIn && isUrlContainDomain) {
        window.location.href = redirectTo;
      } else if (!isUrlContainDomain) {
        return redirectTo;
      }
    }

    return getRedirectToBasedOnPermission(workspacePermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, workspacePermissions]);

  useEffect(() => {
    const body = document.querySelector('body');

    if (isBackgroundWhite(['signup'])) {
      body && body.classList.add('background-white');
    } else {
      body && body.classList.remove('background-white');
    }

    return () => {
      body && body.classList.remove('background-white');
    };
  }, [location]);
  // Set default admin route based on permissions available.
  let adminRoute = useMemo(() => {
    let tempRoute = routes.Users();
    if (workspacePermissions) {
      for (let i = 0; i < adminRouteHierarchy.length; i++) {
        let route = adminRouteHierarchy[i];
        let matched = false;

        for (let j = 0; j < workspacePermissions.length; j++) {
          if (
            workspacePermissions[j].subject === route.subject &&
            workspacePermissions[j].action === route.permission
          ) {
            matched = true;
            break;
          }
        }

        if (matched) {
          tempRoute = route.route;
          break;
        }
      }
    }

    return tempRoute;
  }, [workspacePermissions]);

  const canAccessOrderRequest = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.OrderRequest,
  );

  return (
    <NotificationContextProvider isLoggedIn={isLoggedIn} userId={id}>
      {/* @ts-ignore */}
      <Router>
        <>
          <ReloadPersistedRoute />
          <ScrollToTop />
          {isLoggedIn ? (
            <Switch>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Contacts(':id')}
                subject={AllowedPermissionsSubjectEnum.Contact}>
                <ContactPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Companies(':id')}
                subject={AllowedPermissionsSubjectEnum.Contact}>
                <CompnayPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Labels()}
                subject={AllowedPermissionsSubjectEnum.Label}>
                <Labels />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Undelete}
                path={routes.UndeleteAsset()}
                subject={AllowedPermissionsSubjectEnum.Asset}>
                <UndeleteAssetPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Undelete}
                path={routes.UndeleteInventory()}
                subject={AllowedPermissionsSubjectEnum.Inventory}>
                <UndeleteInventoryPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Undelete}
                path={routes.UndeleteUser()}
                subject={AllowedPermissionsSubjectEnum.User}>
                <UndeleteUserPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Import()}
                subject={AllowedPermissionsSubjectEnum.Import}>
                <ImportsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Undelete()}
                subject={AllowedPermissionsSubjectEnum.Undelete}>
                <UndeletePage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.PrintLabel()}
                subject={AllowedPermissionsSubjectEnum.Label}>
                <PrintLabel />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddCategory()}
                subject={AllowedPermissionsSubjectEnum.Category}>
                <AddCategory />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditCategory(':id')}
                subject={AllowedPermissionsSubjectEnum.Category}>
                <EditCategory />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Categories()}
                subject={AllowedPermissionsSubjectEnum.Category}>
                <Categories />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditSite(':id')}
                subject={AllowedPermissionsSubjectEnum.Site}>
                <EditSite />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddSite()}
                subject={AllowedPermissionsSubjectEnum.Site}>
                <AddSite />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Sites()}
                subject={AllowedPermissionsSubjectEnum.Site}>
                <Sites />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddLocation()}
                subject={AllowedPermissionsSubjectEnum.Location}>
                <AddLocation />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditLocation(':id')}
                subject={AllowedPermissionsSubjectEnum.Location}>
                <EditLocation />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Locations()}
                subject={AllowedPermissionsSubjectEnum.Location}>
                <Locations />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddOrganization()}
                subject={AllowedPermissionsSubjectEnum.Tenant}>
                <AddOrganization />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditOrganizations(':id')}
                subject={AllowedPermissionsSubjectEnum.Tenant}>
                <EditOrganization />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditOrganization(':id')}
                subject={AllowedPermissionsSubjectEnum.Tenant}>
                <EditOrganization />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Organizations()}
                subject={AllowedPermissionsSubjectEnum.Tenant}>
                <Organizations />
              </AccessControlRoute>

              <Route path={routes.InvitePartner()}>
                <InvitePartnerPage />
              </Route>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Manage}
                path={routes.EditPartner(':id')}
                subject={AllowedPermissionsSubjectEnum.PartnerTenant}>
                <EditPartner />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Partners()}
                subject={AllowedPermissionsSubjectEnum.PartnerTenant}>
                <Partners />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddUser()}
                subject={AllowedPermissionsSubjectEnum.User}>
                <AddUser />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditUser(':id')}
                subject={AllowedPermissionsSubjectEnum.User}>
                <EditUser />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Users()}
                subject={AllowedPermissionsSubjectEnum.User}>
                <Users />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddDepartment()}
                subject={AllowedPermissionsSubjectEnum.Department}>
                <AddDepartment />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditDepartment(':id')}
                subject={AllowedPermissionsSubjectEnum.Department}>
                <EditDepartment />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Departments()}
                subject={AllowedPermissionsSubjectEnum.Department}>
                <Departments />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddManufacturer()}
                subject={AllowedPermissionsSubjectEnum.Manufacturer}>
                <AddManufacturer />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditManufacturer(':id')}
                subject={AllowedPermissionsSubjectEnum.Manufacturer}>
                <EditManufacturer />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Manufacturers()}
                subject={AllowedPermissionsSubjectEnum.Manufacturer}>
                <Manufacturer />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddProject()}
                subject={AllowedPermissionsSubjectEnum.Project}>
                <AddProject />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditProject(':id')}
                subject={AllowedPermissionsSubjectEnum.Project}>
                <EditProject />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Projects()}
                subject={AllowedPermissionsSubjectEnum.Project}>
                <Projects />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddRole()}
                subject={AllowedPermissionsSubjectEnum.Role}>
                <AddRole />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.EditRole(':id')}
                subject={AllowedPermissionsSubjectEnum.Role}>
                <EditRole />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Roles()}
                subject={AllowedPermissionsSubjectEnum.Role}>
                <Roles />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Warehouse()}
                subject={AllowedPermissionsSubjectEnum.Warehouse}>
                <Warehouse />
              </AccessControlRoute>

              <Route exact path={routes.Profile()}>
                <ProfilePage />
              </Route>
              {/* Inventory Routes */}
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.InventorySearch()}
                subject={AllowedPermissionsSubjectEnum.Inventory}>
                <Inventories />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.NewInventory()}
                subject={AllowedPermissionsSubjectEnum.Inventory}>
                <AddInventoryPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.EditInventory(':id')}
                subject={AllowedPermissionsSubjectEnum.Inventory}>
                <EditInventory />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.DeletedInventory(':id')}
                subject={AllowedPermissionsSubjectEnum.Inventory}>
                <DeletedInventoryPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.MoveInventory()}
                subject={AllowedPermissionsSubjectEnum.InventoryMove}>
                <MoveInventoryPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.RemoveInventory()}
                subject={AllowedPermissionsSubjectEnum.InventoryRemove}>
                <RemoveInventoryPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.RestockInventory()}
                subject={AllowedPermissionsSubjectEnum.InventoryRestock}>
                <RestockInventoryPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.NewInventoryAuditReport()}
                subject={AllowedPermissionsSubjectEnum.InventoryAudit}>
                <NewInventoryAuditReport />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ConfirmationInventoryEditTransactions(':id')}
                subject={AllowedPermissionsSubjectEnum.Inventory}>
                <EditTransactionsPage />
              </AccessControlRoute>

              {/* Asset Routes */}
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.AssetsSearch()}
                subject={AllowedPermissionsSubjectEnum.Asset}>
                <SearchAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddAssets()}
                subject={AllowedPermissionsSubjectEnum.Asset}>
                <AddAssetPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.EditAssets(':id')}
                subject={AllowedPermissionsSubjectEnum.Asset}>
                <EditAssetPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.DeletedAssets(':id')}
                subject={AllowedPermissionsSubjectEnum.Asset}>
                <DeletedAssetPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.MoveAssets()}
                subject={AllowedPermissionsSubjectEnum.AssetMove}>
                <MoveAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.RemoveAssets()}
                subject={AllowedPermissionsSubjectEnum.AssetRemove}>
                <RemoveAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.RestockAssets()}
                subject={AllowedPermissionsSubjectEnum.AssetRestock}>
                <RestockAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ConfirmationAssetEditTransactions(':id')}
                subject={AllowedPermissionsSubjectEnum.Asset}>
                <AssetEditTransactionsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Reserve()}
                subject={AllowedPermissionsSubjectEnum.AssetReserve}>
                <ReserveAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.CheckOutAssets()}
                subject={AllowedPermissionsSubjectEnum.AssetCheckout}>
                <CheckOutAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.CheckInAssets()}
                subject={AllowedPermissionsSubjectEnum.AssetCheckout}>
                <CheckInAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.NewAssetAuditReport()}
                subject={AllowedPermissionsSubjectEnum.AssetAudit}>
                <NewAssetAuditReport />
              </AccessControlRoute>

              {/* Shipping Route */}
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ShipmentSearch()}
                subject={AllowedPermissionsSubjectEnum.Shipment}>
                <SearchShipmentPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Delete}
                path={routes.ContainerSearch()}
                subject={AllowedPermissionsSubjectEnum.Shipment}>
                <SearchContainerPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ReceivingSchedule()}
                subject={AllowedPermissionsSubjectEnum.Receivable}>
                <ReceivingSchedule />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ReceivingReceive()}
                subject={AllowedPermissionsSubjectEnum.Receivable}>
                <ReceivingReceive />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ReceivingHistory()}
                subject={AllowedPermissionsSubjectEnum.Receivable}>
                <ReceivingHistory />
              </AccessControlRoute>

              {/* File Preview Route */}
              <Route exact path={routes.PreviewFile()}>
                <FilePreviewPage />
              </Route>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Reports()}
                subject={AllowedPermissionsSubjectEnum.Report}>
                <Reports />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.NetworkAssetDetail(':id')}
                subject={AllowedPermissionsSubjectEnum.ItAsset}>
                <NetworkAssetDetail />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.NetworkAssetEdit(':id')}
                subject={AllowedPermissionsSubjectEnum.ItAsset}>
                <NetworkAssetEdit />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.NetworkDashboard()}
                subject={AllowedPermissionsSubjectEnum.ItAssetDashboard}>
                <NetworkDashboardPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.ITAssetUsers()}
                subject={AllowedPermissionsSubjectEnum.ItAsset}>
                <ITAssetUsersPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.NetworkAssets()}
                subject={AllowedPermissionsSubjectEnum.ItAsset}>
                <NetworkAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.NetworkDiscoveryTool()}
                subject={AllowedPermissionsSubjectEnum.ItAssetDiscoveryTool}>
                <NetworkDiscoveryTool />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.NetworkDiscoveredAssets()}
                subject={AllowedPermissionsSubjectEnum.ItAssetDiscoveryAsset}>
                <NetworkDiscoveredAssetsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.NetworkInsights()}
                subject={AllowedPermissionsSubjectEnum.ItAssetInsight}>
                <NetworkAssetsInsights />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.Shops()}
                subject={AllowedPermissionsSubjectEnum.Shop}>
                <Suppliers />
              </AccessControlRoute>

              <AccessControlRoute
                isSuperAdminRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddAffiliateConfiguration()}
                subject={AllowedPermissionsSubjectEnum.AffiliateConfiguration}>
                <AddAffiliateConfigurationPage />
              </AccessControlRoute>

              <AccessControlRoute
                isSuperAdminRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditAffiliateConfiguration(':id')}
                subject={AllowedPermissionsSubjectEnum.AffiliateConfiguration}>
                <EditAffiliateConfigurationPage />
              </AccessControlRoute>

              <AccessControlRoute
                isSuperAdminRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.AffiliateConfiguration()}
                subject={AllowedPermissionsSubjectEnum.AffiliateConfiguration}>
                <AffiliateConfigurationPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.AddAssetModel()}
                subject={AllowedPermissionsSubjectEnum.AssetModel}>
                <AddAssetModelsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Edit}
                path={routes.EditAssetModel(':id')}
                subject={AllowedPermissionsSubjectEnum.AssetModel}>
                <EditAssetModelPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.AssetModels()}
                subject={AllowedPermissionsSubjectEnum.AssetModel}>
                <AssetModelsPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.OrderRequestAgain(':id')}
                subject={AllowedPermissionsSubjectEnum.OrderRequest}>
                <OrderRequestAgainPage />
              </AccessControlRoute>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.OrderRequest()}
                subject={AllowedPermissionsSubjectEnum.OrderRequest}>
                <OrderRequestPage />
              </AccessControlRoute>

              <Route exact path={routes.OrderDetail(':id')}>
                <OrderDetailsPage />
              </Route>

              <Route exact path={routes.InternalOrderDetail(':id')}>
                <InternalOrderDetailPage />
              </Route>

              <Route exact path={routes.ExternalOrderDetail(':id')}>
                <ExternalOrderDetailPage />
              </Route>

              <Route exact path={routes.PrintReturnedOrder()}>
                <PrintReturnedOrderPage />
              </Route>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Access}
                path={routes.OrderHistory()}
                subject={AllowedPermissionsSubjectEnum.OrderRequest}>
                <OrderHistoryPage />
              </AccessControlRoute>

              <Route path={routes.InviteWishlist()}>
                <InviteWishlistPage />
              </Route>

              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.WishlistReferOther()}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <WishlistReferOtherPage />
              </AccessControlRoute>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.CreateWishlistProducts(':id')}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <WishlistEditListPage />
              </AccessControlRoute>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.WishlistCreateList()}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <WishlistCreateListPage />
              </AccessControlRoute>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.EditWishlist(':id')}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <WishlistEditListPage />
              </AccessControlRoute>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.WishlistListView()}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <WishlistListViewPage />
              </AccessControlRoute>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.WishlistSettings()}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <WishlistSettingsPage />
              </AccessControlRoute>
              <AccessControlRoute
                action={AllowedPermissionActionsEnum.Create}
                path={routes.WishlistConfirmationView()}
                subject={AllowedPermissionsSubjectEnum.Wishlist}>
                <ConfirmationViewPage />
              </AccessControlRoute>

              <Route path={routes.WishlistShareWithId(':id')}>
                <WishlistSharePage />
              </Route>

              <Route path={routes.WishlistLandingPage()}>
                <WishlistLandingPage />
              </Route>

              <Route path={routes.Wishlist()}>
                <WishlistPage />
              </Route>

              <Route path={routes.IntegrationsClover()}>
                <CloverLandingPage />
              </Route>

              <Route exact path={routes.Error5XX()}>
                <Error5XX />
              </Route>
              <Redirect
                from={routes.Orders()}
                to={canAccessOrderRequest ? routes.OrderRequest() : routes.OrderHistory()}
              />
              <Redirect from={routes.Admin()} to={adminRoute} />
              <Redirect exact from={routes.Inventory()} to={routes.InventorySearch()} />
              <Redirect exact from={routes.Assets()} to={routes.AssetsSearch()} />
              <Redirect exact from={routes.Wishlist()} to={routes.WishlistListView()} />
              <Redirect exact from={routes.Network()} to={routes.NetworkDashboard()} />
              <Redirect exact from={routes.Shipping()} to={routes.ShipmentSearch()} />
              <Redirect exact from={routes.Receiving()} to={routes.ReceivingSchedule()} />
              <Redirect to={redirectLocation} />
            </Switch>
          ) : (
            <Switch>
              <Route exact path={routes.Login()}>
                <LoginPage />
              </Route>
              <Route exact path={routes.SignUp()}>
                <SignUpPage />
              </Route>
              <Route exact path={routes.ForgotPassword()}>
                <ForgotPassword />
              </Route>
              <Route exact path={routes.SetPassword()}>
                <SetPassword />
              </Route>

              <Route path={routes.WishlistShareWithId(':id')}>
                <WishlistSharePage />
              </Route>

              <Route exact path={routes.Error5XX()}>
                <Error5XX />
              </Route>

              <LoginRedirect />
            </Switch>
          )}
        </>
      </Router>
    </NotificationContextProvider>
  );
}

export default Routes;
