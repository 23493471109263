import { multipleManagersDeleteMessage } from '../utils/multiplemanagerdelete';
const Common = {
  All: 'All',
  Actions: {
    Enter: 'Enter',
    Edit: 'Edit',
    BulkEdit: 'Bulk Edit',
    Delete: 'Delete',
    Undelete: 'Undelete',
    Search: 'Search',
    Add: 'Add',
    Cancel: 'Cancel',
    Close: 'Close',
    Save: 'Save',
    Next: 'Next',
    Connect: "Connect",
    Previous: 'Previous',
    Complete: 'Complete',
    Select: 'Select',
    Ok: 'Ok',
    Back: 'Back',
    Discard: 'Discard',
    Submit: 'Submit',
    Preview: 'Preview',
    Yes: 'Yes',
    No: 'No',
    Update: 'Update',
    UpdateProfile: 'Update Profile',
    UpdatePassword: 'Update Password',
    CreatePassword: 'Create Password',
    UpdateEmail: 'Update Email',
    Send: 'Send',
    Reset: 'Reset Password',
    Print: 'Print Labels',
    Invite: 'Invite',
    Archive: 'Archive',
    Import: 'Import',
    Remove: 'Remove',
    View: 'View',
  },
  Modules: {
    Categories: 'Categories',
    AssetModels: 'Asset Models',
    Locations: 'Locations',
    Departments: 'Departments',
    Manufacturers: 'Manufacturers',
    Projects: 'Projects',
    Sites: 'Sites',
    Labels: 'Labels',
    Organizations: 'Organizations',
    Organization: 'Organization',
    Partners: 'Partners',
    Partner: 'Partner',
    UserRoles: 'User Roles',
    UserAccounts: 'User Accounts',
    Undelete: {
      Undelete: 'Undelete',
      UndeleteAsset: 'Asset',
      UndeleteInventory: 'Inventory',
      UndeleteUser: 'User',
    },
    Import: 'Import',
    Warehouse: 'Warehouse',
    Users: 'Users',
    Roles: 'Roles',
  },
  ErrorMessage: 'A technical issue occurred. Procure logged the issue. Please try again.',
  InputValidationErrorMessage:
    'A validation error has occurred in the input. Check the downloaded file for more deatils.',
  RedirectionMessage: (page: string) => `You may not have permission to view
  ${page} page.`,
  DeleteMessage: 'Are you sure you want to perform delete?',
  DefaultMessage: 'Are you sure you want to perform this action?',
  MultipleItemDeleteMessage: (total: number) =>
    `Are you sure you want to delete ${total} record(s)?`,
  FieldSelectMessage: 'Select the fields you want to overwrite',
  MultipleManagersDeleteMessage: (data: any) => multipleManagersDeleteMessage(data),
  ManagerDeleteWarningMessage: ({
    userName,
    role,
    assignedTo,
  }: {
    userName: string;
    role: string;
    assignedTo: string;
  }) =>
    `${role} is a required field. ${userName} is a ${role} for ${assignedTo}. Please add a new ${role} before deleting ${userName}.`,
  KITDeleteFromSourceSiteOnly: `This Kit will be removed from this site and all items within the kit will be restocked to the location listed in the Kit Summary.`,
  KITDeleteFromNonSiteOnly: `This Kit will be removed from this site and all items within the kit will be marked as consumed.`,
  KITDeleteFromAllSite: `This Kit will be removed from all sites and items within the kit will be restocked to the location in the Kit Summary or marked as consumed and removed from inventory.`,
};

export default Common;
