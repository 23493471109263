import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';
import { CloverAuthInput } from 'app/modules/auth/types';

const useCloverAuthAPI = () => {
  const [fetchStatus, error, data, execute] = useRest(`${API_URL}/auth/clover`);

  const isFetching = fetchStatus === FetchingStatus.Fetching;
  const executeAPI = useCallback(
    async (values: CloverAuthInput) =>
      execute({
        body: JSON.stringify({
          code: values.code,
          clientId: values.clientId,
          merchantId: values.merchantId,
        }),
      }),
    [execute],
  );
  return { fetching: isFetching, error, data, execute: executeAPI };
};

export default useCloverAuthAPI;
