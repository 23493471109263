const routes = {
  Login: (redirectTo?: string) => `/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`,
  SignUp: () => `/signup`,
  SetPassword: () => `/set-password`,
  ForgotPassword: () => `/forgot-password`,
  Admin: () => `/admin`,
  Labels: () => `/admin/labels`,
  Undelete: () => `/admin/undelete`,
  UndeleteAsset: () => `/admin/undelete/asset`,
  UndeleteInventory: () => `/admin/undelete/inventory`,
  UndeleteUser: () => `/admin/undelete/user`,
  Import: () => `/admin/import`,
  PrintLabel: () => `/admin/print-label`,
  Categories: () => `/admin/categories`,
  Contacts: (id: string) => `/companies/contacts/${id}?`,
  Companies: (id: string) => `/companies/${id}?`,
  CompanyContacts: () => `/admin/contacts/companies`,
  AddCategory: () => `/admin/new-category`,
  EditCategory: (id: string) => `/admin/edit-category/${id}`,
  Sites: () => `/admin/sites`,
  AddSite: () => `/admin/new-site`,
  EditSite: (id: string) => `/admin/edit-site/${id}`,
  Locations: () => `/admin/locations`,
  AddLocation: () => `/admin/new-location`,
  EditLocation: (id: string) => `/admin/edit-location/${id}`,
  Organizations: () => `/admin/organizations`,
  AddOrganization: () => `/admin/organizations/add`,
  EditOrganizations: (id: string) => `/admin/organizations/${id}`,
  EditOrganization: (id: string) => `/admin/organization/${id}`,
  Partners: () => `/admin/partners`,
  AddPartner: () => `/admin/partner/add`,
  EditPartner: (id: string) => `/admin/partners/${id}`,
  Users: () => `/admin/users`,
  AddUser: () => `/admin/users/add`,
  EditUser: (id: string) => `/admin/users/${id}`,
  Departments: () => `/admin/departments`,
  AddDepartment: () => `/admin/departments/add`,
  EditDepartment: (id: string) => `/admin/departments/${id}`,
  Manufacturers: () => `/admin/manufacturers`,
  AddManufacturer: () => `/admin/manufacturers/add`,
  EditManufacturer: (id: string) => `/admin/manufacturers/${id}`,
  AssetModels: () => `/admin/asset-models`,
  AddAssetModel: () => `/admin/asset-models/new`,
  EditAssetModel: (id: string) => `/admin/asset-models/edit/${id}`,
  Projects: () => `/admin/projects`,
  AddProject: () => `/admin/new-project`,
  EditProject: (id: string) => `/admin/edit-project/${id}`,
  EditRole: (id: string) => `/admin/roles/${id}`,
  AddRole: () => `/admin/roles/add`,
  Roles: () => `/admin/roles`,
  AddAffiliateConfiguration: () => `/admin/affiliate-configuration/add`,
  EditAffiliateConfiguration: (id: string) => `/admin/affiliate-configuration/edit/${id}`,
  AffiliateConfiguration: () => `/admin/affiliate-configuration`,
  Inventory: () => `/inventory`,
  InventorySearch: () => `/inventory/search`,
  NewInventory: () => `/inventory/new`,
  EditInventory: (id: string) => `/inventory/edit/${id}`,
  DeletedInventory: (id: string) => `/inventory/deleted-inventory/${id}`,
  EditCompany: (id: string) => `/companies/${id}`,
  ConfirmationInventoryEditTransactions: (id: string) =>
    `/inventory/confirmation/edit-transactions/${id}`,
  MoveInventory: (params?: string) => `/inventory/move${params || ''}`,
  RemoveInventory: (params?: string) => `/inventory/remove${params || ''}`,
  RestockInventory: (params?: string) => `/inventory/restock${params || ''}`,
  Assets: () => `/assets`,
  AssetsSearch: (params?: string) => `/assets/search${params || ''}`,
  AddAssets: () => `/assets/new`,
  EditAssets: (id: string) => `/assets/edit/${id}`,
  DeletedAssets: (id: string) => `/assets/deleted-asset/${id}`,
  MoveAssets: (params?: string) => `/assets/move${params || ''}`,
  RemoveAssets: (params?: string) => `/assets/remove${params || ''}`,
  RestockAssets: (params?: string) => `/assets/restock${params || ''}`,
  ConfirmationAssetEditTransactions: (id: string) => `/assets/confirmation/edit-transactions/${id}`,
  Reserve: (params?: string) => `/assets/reserve${params || ''}`,
  CheckOutAssets: (params?: string) => `/assets/checkout${params || ''}`,
  CheckInAssets: () => `/assets/checkin`,
  Shipping: () => `/shipping`,
  Receiving: () => `/receiving`,
  ReceivingSchedule: () => `/receiving/schedule`,
  ReceivingReceive: () => `/receiving/receive`,
  ReceivingHistory: () => `/receiving/history`,
  ShipmentSearch: () => `/shipping/shipment-search`,
  ContainerSearch: () => `/shipping/container-search`,
  Reports: () => `/reports`,
  Shops: () => `/shops`,
  CostReport: () => `/reports/cost-report`,
  LowStockReport: () => `/reports/low-stock-report`,
  SiteReport: () => `/reports/site-report`,
  TransactionReport: () => `/reports/transaction-report`,
  AgingReport: () => `/reports/aging-report`,
  Warehouse: () => `/admin/warehouse`,
  InvitePartner: (inviteToken?: string) =>
    `/admin/partners/invite${inviteToken ? `?token=${inviteToken}` : ''}`,
  AccountingReport: () => `/reports/accounting-report`,
  CustomReport: () => `/reports/custom-report`,
  Profile: () => `/profile`,
  OrderRequestReport: () => `/reports/order-request-report`,
  ItAssetReport: () => `/reports/it-asset-report`,
  AuditReport: () => `/reports/audit-report`,
  NewAssetAuditReport: () => `/assets/new-asset-audit`,
  AssetAuditReportDetails: (id: string) => `/reports/asset-audit-report/${id}`,
  NewInventoryAuditReport: () => `/inventory/new-inventory-audit`,
  InventoryAuditReportDetails: (id: string) => `/reports/inventory-audit-report/${id}`,
  PreviewFile: (fileUrl?: string) => `/file-preview${fileUrl ? `?filePath=${fileUrl}` : ''}`,
  Error5XX: (redirectToPath?: string) =>
    `/error-5xx${redirectToPath ? `?redirectTo=${redirectToPath}` : ''}`,
  Wishlist: () => `/wishlist`,
  IntegrationsClover: () => `/integrations/clover`,
  WishlistLandingPage: () => `/wishlist/landing-page`,
  WishlistReferOther: () => `/wishlist/refer-other`,
  WishlistCreateList: () => `/wishlist/create-list`,
  WishlistListView: () => `/wishlist/list-view`,
  EditWishlist: (id: string) => `/wishlist/edit/${id}`,
  CreateWishlistProducts: (id: string) => `/wishlist/create-list/${id}`,
  WishlistShareWithId: (id: string) => `/wishlist/share/${id}`,
  WishlistSettings: () => `/wishlist/settings`,
  WishlistConfirmationView: () => `/wishlist/confirmation-view`,
  InviteWishlist: (inviteToken?: string) =>
    `/wishlist/invite${inviteToken ? `?token=${inviteToken}` : ''}`,
  Network: () => `/it`,
  NetworkDashboard: () => `/it/dashboard`,
  NetworkAssets: () => `/it/assets`,
  NetworkAssetDetail: (id: string) => `/it/assets/detail/${id}`,
  NetworkAssetEdit: (id: string) => `/it/assets/edit/${id}`,
  NetworkDiscoveryTool: () => `/it/discovery-tool`,
  NetworkDiscoveredAssets: () => `/it/discovered`,
  NetworkInsights: () => `/it/asset-insights`,
  ITAssetUsers: () => `/it/asset-users`,
  Orders: () => `/orders`,
  OrderRequest: () => `/orders/request`,
  OrderRequestAgain: (id: string) => `/orders/request/${id}`,
  OrderHistory: () => `/orders/history`,
  OrderDetail: (id: string) => `/orders/history/${id}`,
  InternalOrderDetail: (id: string) => `/orders/history/internal/${id}`,
  ExternalOrderDetail: (id: string) => `/orders/history/external/${id}`,
  PrintReturnedOrder: () => `/orders/print-returned-order`,
};
export default routes;
