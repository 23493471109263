import { useCallback, useEffect, useMemo, useState } from 'react';
import { adaptNodeEdgeToNode } from 'app/components/AsyncMultiSelect/utils';
import { SnackbarService } from 'app/components/Snackbar';
import Receivables from 'app/i18n/Receivables';
import { Option } from 'app/modules/assetModels/components/FormAssetModelSelect';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { useReceiveItemsMutation } from 'app/modules/receiving/graphql/mutations/generated/receiveItems';
import { useReceivablesQuery } from 'app/modules/receiving/graphql/queries/generated/receivables';
import { useSitesTableQuery } from 'app/modules/sites/views/Sites/graphql/queries/generated/sitesTable';
import { processSearch } from 'app/utils/processSearch';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import usePagination from '../../../../../../hooks/usePagination';
import {
  DateFilter,
  ItemReceivableConfigInput,
  LocationSchema,
  LocationTypeEnum,
  ReceivableSchema,
  ReceivableStatusEnum,
  ReceivableTypeEnum,
  SortOrderEnum,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from '../../../../../../types/schema';
import analytics from 'app/analytics';
import { useReceivableSearchQuery } from 'app/modules/receiving/graphql/queries/receivableSearch';

const PAGE_LIMIT = 50;
export type ReceiveRowType = ReceivableSchema & { editable: boolean } & {
  editableFields: {
    quantityReceived: number;
    dueDate: Date | string;
    carrier?: string;
    trackingNumber?: string;
    vendor?: {
      value: string;
      label: string;
    };
  };
};

interface FilterState {
  dueDate: Date | null;
  vendor: string[];
  orderRequestIds: string[];
}

const useReceiveState = () => {
  const { table } = useEntityManager({
    selection: true,
  });

  const [transactionData, setTransactionData] = useState<{
    open: boolean;
    transaction?: ReceiveRowType | null;
  }>({
    open: false,
    transaction: null,
  });

  const [showConfirmModal, setConfirmModal] = useState(false);
  const [entities, setEntities] = useState<Record<string, ItemReceivableConfigInput>>({});
  const [rows, setRows] = useState<ReceiveRowType[]>([]);
  const [searchStr, setSearchStr] = useState<string>('');
  const [options, setOptions] = useState<any | Option>([]);
  const [activePage, setActivePage] = useState(0);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [filterState, _setFilterState] = useState<FilterState>({
    dueDate: null,
    vendor: [],
    orderRequestIds: [],
  });
  const [vendorOptions, setVendorOptions] = useState<any>([]);

  const dueDateFilter = useMemo(() => {
    if (!filterState.dueDate) {
      return undefined;
    }
    const startTime = new Date(filterState.dueDate);
    startTime.setUTCDate(filterState.dueDate.getDate() - 1);
    startTime.setUTCHours(23, 59, 59, 999);

    const endTime = new Date(filterState.dueDate);
    endTime.setUTCDate(filterState.dueDate.getDate() + 1);
    endTime.setUTCHours(0, 0, 0, 0);

    return {
      timezone: getCurrentTimeZoneValue(),
      absolute: {
        endTime: endTime.toISOString(),
        startTime: startTime.toISOString(),
      },
    } as DateFilter;
  }, [filterState.dueDate]);

  const { fetching, data, onPrevPage, onNextPage, onReset } = usePagination(
    useReceivablesQuery,
    {
      filters: {
        search: searchStr,
        types: [ReceivableTypeEnum.Item],
        statuses: [ReceivableStatusEnum.InProgress],
        vendorIds: filterState?.vendor || [],
        dueDate: dueDateFilter,
        orderRequestIds: filterState?.orderRequestIds || [],
      },
      sorts: !searchStr ? table.state.sorts : null,
    },
    {
      edgeKey: 'receivables',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  const [{ fetching: markingReceivedItems }, markShipmentReceived] = useReceiveItemsMutation();

  const [{ fetching: vendorItemsFetching, data: fetchedVendorOptions }] = useReceivableSearchQuery({
    variables: {
      input: {
        tableName: ValuesInEntitiesTableNameEnum.Receivable,
        distinctByKeys: [ValuesInEntitiesDistinctByKeysEnum.VendorId],
        filters: {
          receivableFilters: {
            types: [ReceivableTypeEnum.OrderRequest],
            statuses: [],
          },
        },
      },
    },
    requestPolicy: 'network-only',
  });  

  useEffect(() => {
    if (fetchedVendorOptions) {
      setVendorOptions(fetchedVendorOptions);
    }
  }, [fetchedVendorOptions]);

  //START below code of block use to fetch the site location options data
  const {
    fetching: siteLoading,
    data: siteData,
    onNextPage: onSiteOptionNextPage,
  } = usePagination(
    useSitesTableQuery,
    {
      filters: {
        search: '',
        types: [LocationTypeEnum.Site],
      },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
    },
    {
      edgeKey: 'locations',
      pageSize: PAGE_LIMIT,
    },
  );

  useEffect(() => {
    if (!activePage) {
      setOptions(siteData?.locations?.edges?.map(adaptNodeEdgeToNode) || []);
    } else if (siteLoading && !fetching) {
      setOptions((prevOptions: any) => {
        const newOptions = siteData?.locations?.edges?.map(adaptNodeEdgeToNode) || [];
        const uniqueOptions = Array.from(new Set([...prevOptions, ...newOptions]));
        return uniqueOptions;
      });
      setLoadingOptions(false);
    }
  }, [activePage, siteData?.locations?.edges, siteLoading, fetching]);

  const onSiteControlScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;

      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;

      if (
        listBoxNode.scrollHeight - position <= 10 &&
        onSiteOptionNextPage &&
        !fetching &&
        !siteLoading &&
        !loadingOptions
      ) {
        onSiteOptionNextPage();
        setActivePage(activePage + 1);
      }
    },
    [onSiteOptionNextPage, fetching, siteLoading, loadingOptions, setActivePage, activePage],
  );

  const _setEntities = (entityId: string, newState: Record<string, ItemReceivableConfigInput>) => {
    setEntities({ ...entities, [entityId]: { ...entities[entityId], ...newState } });
  };

  const siteOptions = useMemo(() => {
    const result = options?.map((items: any) => {
      const item = items as LocationSchema;

      const { searchBy } = processSearch(['name'], '', item);

      return {
        name: searchBy,
        id: item.id,
      };
    });
    if (result.length) return result || [];
  }, [options]);

  //END

  useEffect(() => {
    const rowsWithEditableFields: ReceiveRowType[] =
      data?.receivables?.edges?.map(({ node }) => {
        return {
          ...node,
          editable: false,
          editableFields: {
            quantityReceived: 0,
            dueDate: node.dueDate,
          },
        } as ReceiveRowType;
      }) || [];
    setRows(rowsWithEditableFields);
  }, [data?.receivables?.edges, setRows]);

  const setFilterState = useCallback(
    (nextState: Partial<FilterState>) => {
      _setFilterState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFilterState],
  );

  const filteredEntities = useMemo(() => {
    return Object.values(entities)
      .filter((input) => typeof input?.quantityReceived === 'number')
      .map((input) => ({
        ...input,
        locationId: input.locationId,
        notes: removeExtraSpacesAndNewlines(input?.notes || ''),
        formattedNotes: input?.notes,
      }));
  }, [entities]);

  const hasErrorsInRows = useMemo(() => {
    return filteredEntities.every((entity: any) => {
      return entity?.quantityReceived &&
        entity?.siteId !== "" &&
        entity?.locationId !== ""
    });
  }, [rows, entities]);

  const handleMarkShipmentReceived = useCallback(() => {
    if (filteredEntities?.length > 0) {
      setConfirmModal(false);
      markShipmentReceived({
        input: { receiveItemsConfig: filteredEntities },
      })
        .then((response) => {
          analytics?.track('Mark', { name: 'Shipment Received' });
          if (response?.data?.receiveItems?.success) {
            SnackbarService.show({
              message: Receivables.SuccessMessages.ShipmentMarkedReceived,
            });
            setEntities({});
          } else {
            console.error('[Error in handleCreateCheckInTransaction]', response);
          }
        });
    }
  }, [markShipmentReceived, filteredEntities]);

  const isEntitiesModified = Object.values(entities).some(
    ({ quantityReceived }) => typeof quantityReceived === 'number',
  );

  const onSubmit = useCallback(() => {
    if (isEntitiesModified) {
      setConfirmModal(true);
    }
  }, [isEntitiesModified, showConfirmModal]);

  const onEdit = useCallback(() => {
    const rowId = table.state.selectedRowId;
    const row = rows.find((row) => row.id === rowId);

    setTransactionData({
      open: true,
      transaction: row,
    });
  }, [rows, table.state.selectedRowId]);

  const closeConfirmation = useCallback(() => {
    setConfirmModal(false);
  }, []);

  const resetTransactionData = useCallback(() => {
    setTransactionData({ open: false, transaction: null });
    table.setState({ selectedRowId: '' });
  }, []);

  return {
    table,
    rows,
    entities,
    showConfirmModal,
    setEntities,
    isEntitiesModified,
    rowCount: data?.receivables?.totalCount || 0,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    markingReceivedItems,
    handleMarkShipmentReceived,
    filterState,
    setFilterState,
    filteredEntities,
    onSubmit,
    closeConfirmation,
    setSearchStr,
    searchStr,
    siteOptions,
    onEdit,
    transactionData,
    resetTransactionData,
    onSiteControlScroll,
    hasErrorsInRows,
    vendorOptions
  };
};

export type UseCheckInAssetsStateReturnType = ReturnType<typeof useReceiveState>;
export default useReceiveState;
